<template>
  <div v-if="operatorGroup" class="mt-10">
    <v-form class="form-grid" @submit.prevent="saveOperatorGroup" ref="formOperatorGroup">
      <v-row>
        <v-col>
          <form-field
              :label="$t('name')"
              v-model="operatorGroup.name"
              readonly
              col-field="9"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <form-field
              :label="$t('company')"
              v-model="operatorGroup.companyName"
              readonly
              col-field="9"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <form-field
              :label="$t('network')"
              v-model="operatorGroup.network"
              readonly
          />
        </v-col>
        <v-col>
          <form-field
              :label="$t('role')"
              v-model="operatorGroup.role"
              readonly
              col-field="9"
          />
        </v-col>
      </v-row>


      <headline  class="mt-16">
        Groupe d'opérateurs parent
      </headline>
      <v-row>
        <v-col>
          <operator-group-select
              :label="$tc('operator-group.operator-group')"
              v-model="operatorGroup.billingParentId"
              :filters="operatorGroupFilter"
              :role="operatorGroup.role"
          />
        </v-col>
      </v-row>
    </v-form>

      <headline  class="mt-16">
        {{ $tc('operator.operator', 2) }}
        <v-btn class="right" small @click="openManageOperators()">
          {{ $t('agreement.manage-operators') }}
        </v-btn>
      </headline>
      <v-row>
        <v-col class="text-center">
          <v-chip class="ml-2 mb-3" dark :color="operatorGroup.role === 'CPO' ? 'red' : 'blue'" v-for="operatorId in operatorGroup.operators" :key="operatorId">
            {{ operatorId }}
          </v-chip>
        </v-col>
      </v-row>

    <v-form class="form-grid" @submit.prevent="saveBillingDetails" ref="formBillingDetail">
      <headline  class="mt-16">
        {{ $tc('billing-details.billing-details', 2) }}
        <v-btn class="right" small @click="addBillingDetail">
          {{ $t('billing-details.add') }}
        </v-btn>
      </headline>
      <v-row justify="center">
        <v-col cols="6" v-for="(detail,i) in billingDetails" :key="detail.id" class="mb-6">
          <v-card>
            <v-card-title>
              <v-row>
                <v-col>
                  {{ !detail.country ? $t('billing-details.all-countries') : detail.country}}
                  <v-btn class="float-right" x-small icon @click="deleteBillingDetail(detail.id)">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <billing-detail
                  v-model="billingDetails[i]"
                  :readonly="false"
                  :canSetAllCountries="!detail.country || !hasAllCountries"
                  :filter-country="billingDetailFilterCountry(detail)"
              ></billing-detail>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>


      <v-row>
        <v-col class="text-center">
          <v-btn color="primary" type="submit">
            {{ $t('save') }}
          </v-btn>
        </v-col>
      </v-row>


    </v-form>


    <v-dialog v-model="manageOperatorsModal" max-width="800">
      <v-card>
        <v-card-title>
          {{ $t('agreement.manage-operators')}}
        </v-card-title>
        <v-card-text>
          <v-textarea
              v-model="manageOperators"
              :label="$tc('operator.operator', 2)"
              placeholder="FR*ABC, BE*EFG, ..."
              multiple
              chips
              deletable-chips
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col class="text-right">
              <v-btn small @click="manageOperatorsModal = false">
                {{ $t('close') }}
              </v-btn>
              <v-btn small class="ml-3" color="primary" @click="saveOperators">
                {{ $t('update') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>

import OperatorGroupRepository from "@repository/OperatorGroupRepository";
import RulesMixin from "@mixins/RulesMixin";
import BillingDetail from "@/components/elements/BillingDetail.vue";
import Operator from "@pages/Operator/Operator.vue";
import OperatorGroupSelect from "@blocks/Select/OperatorGroupSelect.vue";

export default {
  components: {
    OperatorGroupSelect,
    BillingDetail,
  },
  data() {
    return {
      operatorGroup: null,
      manageOperatorsModal: false,
      manageOperators: null,
      billingDetails: [],
    }
  },

  mounted() {
    this.loadData()
  },

  mixins: [
    RulesMixin,
  ],

  computed: {
    id() {
      return this.$parent.operatorGroup?.id ?? null
    },

    hasAllCountries() {
      return this.billingDetails.some(detail => !detail.country)
    },

  },

  watch: {
    'operatorGroup.billingParentId'(newVal) {
      this.saveOperatorGroup()
    },
  },

  methods: {
    loadData() {
      this.showLoading(true)
      OperatorGroupRepository.get(this.id).then(data => {
        let billingDetails = []
        if(data.billingDetails?.length > 0) {
          billingDetails = data.billingDetails
        }
        if(billingDetails.length === 0) {
          billingDetails.push({
            allCountries: true,
            country: null,
            address: {
              number: null,
              street: null,
              zipcode: null,
              city: null,
              country: null,
            }
          })
        } else {
          billingDetails.forEach(detail => {
            detail.allCountries = !detail.country
          })
        }
        this.billingDetails = billingDetails
        this.operatorGroup = data
        this.showLoading(false)
      }).catch(err => {
        this.notifyError(err)
        this.showLoading(false)
      })
    },

    openManageOperators() {
      this.manageOperators = this.operatorGroup.operators?.join(", ")
      this.manageOperatorsModal = true
    },

    addBillingDetail() {
      this.billingDetails.push({
        country: "",
        address: {
          number: null,
          street: null,
          zipcode: null,
          city: null,
          country: null,
        }
      })
    },

    saveBillingDetails() {
      if(this.$refs.formBillingDetail.validate()) {
        this.showLoading(true)
        OperatorGroupRepository.updateBillingDetails(this.id, this.billingDetails).then(() => {
          this.notifySuccess('update-success')
          this.loadData()
        }).catch(error => {
          this.notifyError(error)
          this.showLoading(false)
        })
      }
    },


    saveOperators() {

      const operators = []
      let operatorsString = this.manageOperators?.split(/\r?\n|\r|\n|,| |;|\./g) ?? []
      for(let operator of operatorsString) {
        if(operator) {
          if(!operators.includes(operator)) {
            operators.push(operator)
          }
        }
      }

      this.showLoading(true)
      OperatorGroupRepository.updateOperators(this.id, operators).then(() => {
        this.manageOperators = null
        this.manageOperatorsModal = false
        this.loadData()
      }).catch(error => {
        this.notifyError(error)
        this.showLoading(false)
      })

    },

    saveOperatorGroup() {
      this.showLoading(true)
      OperatorGroupRepository.updateOperatorGroup(this.id, this.operatorGroup).then(() => {
        this.notifySuccess('update-success')
        this.showLoading(false)
      }).catch(error => {
        this.notifyError(error)
        this.showLoading(false)
      })
    },

    billingDetailFilterCountry(detail) {
      return (country) => {
        console.log(detail.country, country.code)
        if(detail.country === country.code) {
          return true
        }
        return !this.billingDetails.some(d => d.country === country.code)
      }
    },

    deleteBillingDetail(id) {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if (result) {
          this.billingDetails = this.billingDetails.filter(detail => detail.id !== id)
        }
      })
    },

    operatorGroupFilter(group) {
      return group._id !== this.operatorGroup.id
    },


  }
}
</script>

<style lang="scss">

</style>
