<template>
  <div v-if="operatorGroup" class="mt-10">
    <v-row>
      <v-col>
        <v-row class="form-field">
          <v-col cols="3" class="label">
            {{ $t('name') }}
          </v-col>
          <v-col class="input-wrapper">
            <v-row>
              <v-col cols="6">
                <v-text-field
                    :value="operatorGroup.name"
                    hide-details
                    readonly
                />
              </v-col>
              <v-col>
                <v-btn class="mt-3" small @click="$router.push({name: 'operator-group.edit'})">
                  {{ $t('edit') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <form-field
            :label="$t('company')"
            v-model="operatorGroup.companyName"
            readonly
            col-field="9"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <form-field
            :label="$t('network')"
            v-model="operatorGroup.network"
            readonly
        />
      </v-col>
      <v-col cols="3">
        <form-field
            :label="$t('role')"
            v-model="operatorGroup.role"
            readonly
            col-field="9"
        />
      </v-col>
      <v-col  cols="5">
        <form-field
            :label="$t('operator-group.is-network')"
            :value="operatorGroup.isNetworkOperator ? $t('yes') : $t('no')"
            readonly
            col-field="9"
        />
      </v-col>
    </v-row>

    <template v-if="operatorGroup.billingParentId">
      <headline  class="mt-16">
        Groupe d'opérateurs parent
      </headline>
      <v-row>
        <v-col>
          <operator-group-select
              v-model="operatorGroup.billingParentId"
              readonly
          />
        </v-col>
      </v-row>
    </template>

    <headline  class="mt-16">
      {{ $tc('operator.operator', 2) }}
    </headline>
    <v-row>
      <v-col class="text-center">
        <v-chip class="ml-2 mb-3" dark :color="operatorGroup.role === 'CPO' ? 'red' : 'blue'" v-for="operatorId in operatorGroup.operators" :key="operatorId">
          {{ operatorId }}
        </v-chip>
      </v-col>
    </v-row>

    <headline  class="mt-16">
      {{ $tc('billing-details.billing-details', 2) }}
    </headline>
    <v-row>
      <v-col cols="12" v-for="(detail,i) in billingDetails" :key="detail.id" class="mb-6">
        <v-row>
          <v-col cols="3">
            {{ !detail.country ? $t('billing-details.all-countries') : detail.country}}
          </v-col>
          <v-col>
            <v-card>
              <v-card-text>
                <billing-detail
                    v-model="billingDetails[i]"
                    hide-country
                ></billing-detail>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

      </v-col>
    </v-row>






  </div>
</template>

<script>

import OperatorGroupRepository from "@repository/OperatorGroupRepository";
import RulesMixin from "@mixins/RulesMixin";
import BillingDetail from "@/components/elements/BillingDetail.vue";
import OperatorGroupSelect from "@blocks/Select/OperatorGroupSelect.vue";

export default {
  components: {
    OperatorGroupSelect,
    BillingDetail,
  },
  data() {
    return {
      operatorGroup: null,
      manageOperatorsModal: false,
      manageOperators: null,
      billingDetails: [],
    }
  },

  mounted() {
    this.loadData()
  },

  mixins: [
    RulesMixin,
  ],

  computed: {
    id() {
      return this.$parent.operatorGroup?.id ?? null
    },

  },

  methods: {
    loadData() {
      this.showLoading(true)
      OperatorGroupRepository.get(this.id).then(data => {
        let billingDetails = []
        if(data.billingDetails?.length > 0) {
          billingDetails = data.billingDetails
        }
        if(billingDetails.length === 0) {
          billingDetails.push({
            allCountries: true,
            country: null,
            address: {
              number: null,
              street: null,
              zipcode: null,
              city: null,
              country: null,
            }
          })
        } else {
          billingDetails.forEach(detail => {
            detail.allCountries = !detail.country
          })
        }
        this.billingDetails = billingDetails
        this.operatorGroup = data
        this.showLoading(false)
      }).catch(err => {
        this.notifyError(err)
        this.showLoading(false)
      })
    },

  }
}
</script>

<style lang="scss">

</style>
